// eslint-disable-next-line
import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { compose } from 'recompose';
import { withAuthorization, withEmailVerification } from '../../services/Session';
import { UserList, UserItem } from '../../components/Users';
import * as ROUTES from '../../constants/routes';
import 'twin.macro';

const AdminPage = () => (
  <div tw="h-screen">
    <Switch>
      <Route exact path={ROUTES.ADMIN} component={UserList} />
      <Route exact path={ROUTES.ADMIN_DETAILS} component={UserItem} />
    </Switch>
  </div>
);

const condition = (authUser: any) =>
  authUser &&
  (authUser.customClaims.roles.includes('admin') ||
    authUser.customClaims.roles.includes('customerService'));

export default compose(withEmailVerification, withAuthorization(condition))(AdminPage);
