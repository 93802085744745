// eslint-disable-next-line
import React from 'react';
import { compose } from 'recompose';
import 'twin.macro';
import { withAuthorization, withEmailVerification } from '../../services/Session';

const DashboardPage = () => (
  <main tw="flex-1 relative z-0 overflow-y-auto py-6 focus:outline-none">
    <div tw="max-w-6xl mx-auto px-4 sm:px-6 md:px-8">
      <h1 tw="text-xl font-semibold text-gray-900"></h1>
    </div>
    <div tw="max-w-6xl mx-auto px-4 sm:px-6 md:px-8">
      <div tw="py-4">
        <div tw="w-full h-screen"></div>
      </div>
    </div>
  </main>
);
const condition = (authUser: any) =>
  authUser && authUser.customClaims.roles.includes('dashboardAccess');

export default compose(withEmailVerification, withAuthorization(condition))(DashboardPage);
