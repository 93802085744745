// eslint-disable-next-line
import React from 'react'
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
/* eslint-disable  @typescript-eslint/no-unused-vars */
import tw from 'twin.macro';

interface SIProps {
  firebase?: any;
  history?: any;
  onInputText: any;
}

interface SIState {
  searchText: string;
}

const INITIAL_STATE = {
  searchText: '',
  searchIcon: faSearch,
};

class SearchInput extends React.Component<SIProps, SIState> {
  constructor(props: any) {
    super(props);

    this.state = {
      ...INITIAL_STATE,
    };
  }

  onTextChange = (e: any) => {
    const searchText = e.target.value;
    this.props.onInputText(searchText);
    this.setState({ searchText });
  };

  render() {
    const { searchIcon }: any = this.state;
    return (
      <div tw="w-full flex md:ml-0 flex-row">
        <label tw="sr-only">Search by Email or First & Last Name</label>
        <div tw="relative w-full text-gray-500 focus-within:text-gray-700">
          <div tw="absolute inset-y-0 left-0 flex items-center pointer-events-none">
            <FontAwesomeIcon icon={searchIcon} />
          </div>
          <input
            id="search_field"
            tw="block w-full h-full pl-8 pr-3 py-2 rounded-md text-gray-900 placeholder-gray-800 focus:outline-none focus:placeholder-gray-600 sm:text-sm italic font-semibold"
            name="s"
            placeholder="Search by Email or First & Last Name"
            type="search"
            value={this.state.searchText}
            onChange={this.onTextChange}
          />
        </div>
      </div>
    );
  }
}

export default SearchInput;
