export const parseQueryString = (query: string) => {
  const consts = query.split('&');
  const queryString: any = {};
  for (let i = 0; i < consts.length; i++) {
    const pair = consts[i].split('=');
    const key: any = decodeURIComponent(pair[0]);
    const value = decodeURIComponent(pair[1]);
    if (typeof queryString[key] === 'undefined') {
      queryString[key] = decodeURIComponent(value);
    } else if (typeof queryString[key] === 'string') {
      const arr = [queryString[key], decodeURIComponent(value)];
      queryString[key] = arr;
    } else {
      queryString[key].push(decodeURIComponent(value));
    }
  }
  return queryString;
};
