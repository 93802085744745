// eslint-disable-next-line
import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AuthUserContext } from '../services/Session';
import { faBars, faUser, faSignOutAlt, faUserLock } from '@fortawesome/free-solid-svg-icons';
import SignOutButton from '../pages/Auth/SignOut';
import * as ROUTES from '../constants/routes';
import { Link } from 'react-router-dom';
import Search from '../forms/Search';
import 'twin.macro';

const Toolbar = (props: any) => (
  <AuthUserContext.Consumer>
    {(authUser) => (authUser ? <ToolbarAuth {...props} /> : <ToolbarNonAuth />)}
  </AuthUserContext.Consumer>
);

const ToolbarNonAuth = () => null;

const ToolbarAuth = ({ isOpen, setIsOpen }: any) => {
  const [userMenuIsOpen, setUserMenuIsOpen] = useState(false);

  return (
    <div tw="relative flex-shrink-0 flex h-16 bg-white shadow">
      <button
        tw="px-4 border-r border-gray-200 text-gray-500 focus:outline-none focus:bg-gray-100 focus:text-gray-600 md:hidden"
        aria-label="Open Sidebar"
        onClick={() => setIsOpen(!isOpen)}
      >
        <FontAwesomeIcon icon={faBars} />
      </button>
      <div tw="flex-1 px-4 flex justify-between">
        <Search />
        <div tw="ml-4 flex items-center md:ml-6">
          <div tw="ml-3 relative" onClick={() => setUserMenuIsOpen(!userMenuIsOpen)}>
            <div>
              {userMenuIsOpen ? (
                <button
                  tw="p-1 text-white rounded-full bg-primary-500 hover:bg-primary-100 hover:text-white focus:outline-none focus:shadow-outline focus:text-gray-500"
                  id="user-menu"
                  aria-label="User menu"
                  aria-haspopup="true"
                >
                  <FontAwesomeIcon icon={faUser} />
                </button>
              ) : (
                <button
                  tw="p-1 text-gray-500 rounded-full hover:bg-primary-200 hover:text-white focus:outline-none focus:shadow-outline focus:text-gray-600"
                  id="user-menu"
                  aria-label="User menu"
                  aria-haspopup="true"
                >
                  <FontAwesomeIcon icon={faUser} />
                </button>
              )}
            </div>
            {userMenuIsOpen && (
              <div tw="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg">
                <div
                  tw="py-1 rounded-md bg-white shadow-xs"
                  role="menu"
                  aria-orientation="vertical"
                  aria-labelledby="user-menu"
                >
                  <div
                    tw="cursor-pointer block px-4 py-2 text-sm text-gray-900 hover:text-white hover:bg-primary-100 transition ease-in-out duration-150"
                    role="menuitem"
                  >
                    <FontAwesomeIcon icon={faUserLock} tw="mr-2" />
                    <Link to={ROUTES.AUTHENTICATION}>Authentication</Link>
                  </div>
                  <div
                    tw="cursor-pointer block px-4 py-2 text-sm text-gray-900 hover:text-white hover:bg-primary-100 transition ease-in-out duration-150"
                    role="menuitem"
                  >
                    <FontAwesomeIcon icon={faSignOutAlt} tw="mr-2" />
                    <SignOutButton />
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Toolbar;
