class validateField {
  static isEmail = (email: string) => {
    const re = /^[_a-z0-9-]+(\.[_a-z0-9-]+)*(\+[a-z0-9-]+)?@[a-z0-9-]+(\.[a-z0-9-]+)*$/i;
    return re.test(String(email).toLowerCase());
  };

  static hasSpaces = (spacedString: string) => {
    const re = /\+/g;
    return re.test(String(spacedString).toLowerCase());
  };

  static hasNumber = (numberString: string) => {
    const re = /\d/;
    return re.test(String(numberString).toLowerCase());
  };
}

export default validateField;
