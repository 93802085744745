// eslint-disable-next-line
import React from 'react';
import { SignInForm } from '../forms/SignInForm';
import bigLogo from '../assets/images/icons/vertical-logo.png';
import logo from '../assets/images/icons/logo.png';
import tw from 'twin.macro';

const Container = tw.div`relative min-h-screen bg-primary-700 text-white font-medium flex justify-center`;
const Content = tw.div`max-w-screen-xl m-0 sm:mx-20 sm:my-16 bg-white text-gray-900 shadow sm:rounded-lg flex justify-center flex-1`;
const MainContainer = tw.div`lg:w-1/2 xl:w-5/12 p-6 sm:p-12 sm:self-center`;
const LogoLink = tw.a``;
const LogoImage = tw.img`h-48 mx-auto xl:hidden`;
const LogoIcon = tw.img`h-24 mx-auto hidden xl:block`;
const MainContent = tw.div`mt-4 flex flex-col items-center`;
const IllustrationContainer = tw.div`sm:rounded-r-lg flex-1 bg-gray-100 text-center hidden xl:flex justify-center align-middle`;
const IllustrationImage = tw.img` w-auto object-scale-down p-56`;

const SignInPage = () => (
  <Container>
    <Content>
      <MainContainer>
        <LogoLink href={'https://truthtotable.com'} target="_blank">
          <LogoImage src={bigLogo} />
          <LogoIcon src={logo} />
        </LogoLink>
        <MainContent>
          <SignInForm />
        </MainContent>
      </MainContainer>
      <IllustrationContainer>
        <IllustrationImage src={bigLogo} />
      </IllustrationContainer>
    </Content>
  </Container>
);

export default SignInPage;
