// eslint-disable-next-line
import React from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';

import AuthUserContext from './context';
import { withFirebase } from '..';
import * as ROUTES from '../../constants/routes';

interface Props {
  firebase?: any;
  history?: any;
}

const withAuthorization = (condition: any) => (Component: any) => {
  class WithAuthorization extends React.Component<Props> {
    listener: any;
    componentDidMount() {
      this.listener = this.props.firebase.onAuthUserListener(
        (authUser: any) => {
          if (!condition(authUser)) {
            this.props.history.push(ROUTES.SIGN_IN);
          }
        },
        () => this.props.history.push(ROUTES.SIGN_IN),
      );
    }

    componentWillUnmount() {
      this.listener();
    }

    render() {
      return (
        <AuthUserContext.Consumer>
          {(authUser: any) => (condition(authUser) ? <Component {...this.props} /> : null)}
        </AuthUserContext.Consumer>
      );
    }
  }
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore:1048
  return compose(withRouter, withFirebase)(WithAuthorization);
};

export default withAuthorization;
