import app from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/functions';
import 'firebase/performance';
import Fingerprint2 from 'fingerprintjs2';
import { reloadBrowser } from '../../utils/reloadBrowser';

const config = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_WEB_APP_ID,
};

let fingerprint = '';
let deviceInfo = {};

class Firebase {
  fieldValue: typeof app.firestore.FieldValue;
  emailAuthProvider: typeof app.auth.EmailAuthProvider;
  auth: app.auth.Auth | any;
  db: app.firestore.Firestore | any;
  functions: app.functions.Functions;
  googleProvider: app.auth.GoogleAuthProvider;
  performance: app.performance.Performance;

  constructor() {
    app.initializeApp(config);
    app.firestore().settings(
      // window.location.hostname === 'localhost'
      // ? { host: 'localhost:8080', ssl: false, ignoreUndefinedProperties: true }
      // : { ignoreUndefinedProperties: true },
      { ignoreUndefinedProperties: true },
    );
    this.fieldValue = app.firestore.FieldValue;
    this.emailAuthProvider = app.auth.EmailAuthProvider;
    this.auth = app.auth();
    this.db = app.firestore();
    this.functions = app.functions();
    this.googleProvider = new app.auth.GoogleAuthProvider();
    this.performance = app.performance();
    this.getUserInfo();
  }

  getUserInfo = async () => {
    Fingerprint2.get({}, (components: any) => {
      const values = components.map((component: any) => {
        return component.value;
      });
      fingerprint = Fingerprint2.x64hash128(values.join(''), 31);
      deviceInfo = {
        components: JSON.stringify(components),
        fingerprint,
        lastLogin: new Date(),
      };
    });
  };

  doHttpsCallable = async (functionName: string, args?: any) => {
    if (args) {
      const functionWithArgs = this.functions.httpsCallable(functionName);
      return await functionWithArgs(args);
    } else this.functions.httpsCallable(functionName);
  };

  doSignInAnonymously = (): string =>
    this.auth.signInAnonymously().then((data: any) => data.user.uid);

  doCreateUserWithEmailAndPassword = (email: string, password: string) =>
    this.auth.createUserWithEmailAndPassword(email, password);

  doSignInWithEmailAndPassword = (email: string, password: string) =>
    this.auth.signInWithEmailAndPassword(email, password);

  doSignInWithGoogle = () => this.auth.signInWithPopup(this.googleProvider);

  doSignOut = () => {
    this.auth.signOut();
    return reloadBrowser();
  };

  doPasswordReset = (email: string) => this.auth.sendPasswordResetEmail(email);

  doSendEmailVerification = () =>
    this.auth.currentUser.sendEmailVerification({
      url: `${window.location.origin}/${process.env.REACT_APP_CONFIRMATION_EMAIL_REDIRECT}`,
    });

  doPasswordUpdate = (password: string) => this.auth.currentUser.updatePassword(password);

  onAuthUserListener = (next: any, fallback: any) =>
    this.auth.onAuthStateChanged(async (authUser: any) => {
      if (authUser) {
        if (authUser.isAnonymous) {
          this.admin(authUser.uid).set(
            {
              created: app.firestore.Timestamp.now(),
            },
            { merge: true },
          );
          this.db
            .collection('admins')
            .doc(authUser.uid)
            .collection('devices')
            .doc(`Web Browser - ${fingerprint}`)
            .set(deviceInfo, { merge: true });
        } else {
          const userToken = await this.auth.currentUser.getIdTokenResult(true);
          const roles = userToken.claims.customClaims && userToken.claims.customClaims.roles;
          this.admin(authUser.uid)
            .get()
            .then((snapshot: any) => {
              const dbUser: any = snapshot.data();
              if (!dbUser.customClaims) dbUser.customClaims = { roles };
              authUser = {
                uid: authUser.uid,
                email: authUser.email,
                emailVerified: authUser.emailVerified,
                providerData: authUser.providerData,
                photoURL: authUser.photoURL,
                ...dbUser,
              };
              next(authUser);
            })
            .catch((error: any) => console.error(error));
        }
      } else {
        fallback();
      }
    });

  admin = (uid: string) => this.db.doc(`admins/${uid}`);

  admins = () => this.db.collection('admins');

  user = (uid: string) => this.db.doc(`user/${uid}`);

  users = () => this.db.collection('users');

  message = (uid: string) => this.db.doc(`messages/${uid}`);

  messages = () => this.db.collection('messages');
}

export default Firebase;
