// eslint-disable-next-line
import React, { Component } from 'react';
/* eslint-disable  @typescript-eslint/no-unused-vars */
import tw from 'twin.macro';
import { withFirebase } from '../../services';
import Alert from '@reach/alert';
import * as yup from 'yup';
import { Formik, Form as FormikForm } from 'formik';
import Loading from '../../utils/loadingAnimation';
import styled from 'styled-components';

interface PCProps {
  firebase?: any;
  authUser?: any;
}

interface PCState {
  error: any;
  success: boolean;
  loading: boolean;
}

const INITIAL_STATE = {
  error: null,
  success: false,
  loading: false,
};

const Form = styled(FormikForm)``;

class PasswordChangeForm extends Component<PCProps, PCState> {
  constructor(props: any) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }

  render() {
    const { error, loading, success }: any = this.state;

    return (
      <Formik
        initialValues={{ password: '', passwordOne: '', passwordTwo: '' }}
        onSubmit={async (values: any, { setSubmitting, resetForm }) => {
          setSubmitting(true);
          this.setState({ loading: true });
          const { password, passwordOne } = values;
          this.props.firebase
            .doSignInWithEmailAndPassword(this.props.firebase.auth.currentUser.email, password)
            .then(() =>
              this.props.firebase
                .doPasswordUpdate(passwordOne)
                .then(() => {
                  setSubmitting(false);
                  resetForm({});
                  this.setState({ error: false, success: true, loading: false });
                })
                .catch((error: any) => {
                  setSubmitting(false);
                  resetForm({});
                  this.setState({ error, loading: false, success: false });
                }),
            )
            .catch((error: any) => {
              setSubmitting(false);
              resetForm({});
              if (
                this.props.firebase.auth.currentUser.email === null ||
                this.props.firebase.auth.currentUser.email === undefined
              )
                this.setState({
                  error: {
                    message:
                      'No email is associated with this account. Link a password above to enable password resets.',
                  },
                  loading: false,
                  success: false,
                });
              else this.setState({ error, loading: false, success: false });
            });
        }}
        validationSchema={yup.object().shape({
          password: yup
            .string()
            .label('Current Password')
            .min(8)
            .required('Current Password is Required'),
          passwordOne: yup.string().label('Password').min(8).required('Password is Required'),
          passwordTwo: yup
            .string()
            .label('Password Confirm')
            .min(8)
            .oneOf([yup.ref('passwordOne')], 'Passwords Do NOT Match')
            .required('Password confirm is required'),
        })}
      >
        {({
          values,
          handleChange,
          errors,
          setFieldTouched,
          isValid,
          touched,
          handleSubmit,
        }: any) => {
          return (
            <>
              {loading && <Loading />}
              <Form tw="mt-3 grid grid-cols-1 row-gap-6 col-gap-4 sm:grid-cols-6">
                <div tw="sm:col-span-6">
                  <div tw="mt-1 rounded-md shadow-sm w-1/2">
                    <input
                      name="password"
                      value={values.password}
                      onChange={handleChange('password')}
                      onBlur={() => setFieldTouched('password')}
                      type="password"
                      placeholder="Current Password"
                      tw="form-input block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                    />
                  </div>
                </div>
                <div tw="sm:col-span-3">
                  <div tw="mt-1 rounded-md shadow-sm">
                    <input
                      name="passwordOne"
                      value={values.passwordOne}
                      onChange={handleChange('passwordOne')}
                      onBlur={() => setFieldTouched('passwordOne')}
                      type="password"
                      placeholder="New Password"
                      tw="form-input block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                    />
                  </div>
                </div>
                <div tw="sm:col-span-3">
                  <div tw="mt-1 rounded-md shadow-sm">
                    <input
                      name="passwordTwo"
                      value={values.passwordTwo}
                      onChange={handleChange('passwordTwo')}
                      onBlur={() => setFieldTouched('passwordTwo')}
                      type="password"
                      placeholder="Confirm New Password"
                      tw="form-input block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                    />
                  </div>
                </div>
                <div tw="sm:col-span-3">
                  <div tw="mt-1 rounded-md">
                    <button
                      disabled={!isValid}
                      onClick={handleSubmit}
                      type="submit"
                      tw="inline-flex justify-center py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-primary-600 hover:bg-primary-500 focus:outline-none focus:border-primary-700 focus:shadow-outline active:bg-primary-700 transition duration-150 ease-in-out"
                    >
                      Reset Password
                    </button>
                  </div>
                </div>
              </Form>
              {success && (
                <Alert tw="my-8 w-full rounded-md bg-green-100 p-4">
                  <div tw="flex">
                    <div tw="flex-shrink-0">
                      <svg tw="h-5 w-5 text-green-400" viewBox="0 0 20 20" fill="currentColor">
                        <path
                          fillRule="evenodd"
                          d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </div>
                    <div tw="ml-3">
                      <h3 tw="text-sm leading-5 font-medium text-green-800">
                        Your password has been successfully updated!
                      </h3>
                    </div>
                  </div>
                </Alert>
              )}
              {(error?.message || errors?.password || errors?.passwordTwo || errors?.passwordOne) &&
                (touched.password || touched.passwordOne || touched.passwordTwo) && (
                  <Alert tw="my-8 w-full rounded-md bg-red-100 p-4">
                    <div tw="flex">
                      <div tw="flex-shrink-0">
                        <svg tw="h-5 w-5 text-red-400" viewBox="0 0 20 20" fill="currentColor">
                          <path
                            fillRule="evenodd"
                            d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
                            clipRule="evenodd"
                          />
                        </svg>
                      </div>
                      <div tw="ml-3">
                        <div tw="text-sm leading-5 text-red-700">
                          <p>{error && error.message}</p>
                          <p>{errors && errors.password}</p>
                          <p>{errors && errors.passwordOne}</p>
                          <p>{errors && errors.passwordTwo}</p>
                        </div>
                      </div>
                    </div>
                  </Alert>
                )}
            </>
          );
        }}
      </Formik>
    );
  }
}

export default withFirebase(PasswordChangeForm);
