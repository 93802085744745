// eslint-disable-next-line
import React, { useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  AlertDialog as ReactAlertDialog,
  AlertDialogLabel,
  AlertDialogDescription,
} from '@reach/alert-dialog';
import '@reach/dialog/styles.css';
import tw from 'twin.macro';
import {
  faInfoCircle,
  faExclamationTriangle,
  faCheckCircle,
} from '@fortawesome/free-solid-svg-icons';

import styled from 'styled-components';

const Heading = tw.h1`text-2xl xl:text-3xl font-extrabold text-center mb-8`;
const ErrorText = tw.p`italic text-center py-4 mt-8`;
const StandardText = tw.p` text-center py-4 mt-8`;
const Container = tw.div`flex-1 sm:m-0 m-8`;
const Content = tw.div`flex flex-col items-center my-8`;
const ButtonContainer = tw.div`flex items-center flex-col-reverse sm:flex-row`;
const DismissButton = styled.button`
  ${tw`cursor-pointer w-64 mt-5 tracking-wide font-semibold bg-primary-500 text-gray-100 py-4 rounded-lg hover:bg-primary-700 transition-all duration-300 ease-in-out flex items-center justify-center focus:shadow-outline focus:outline-none`}
  ${({ disabled }) => (disabled ? tw`cursor-not-allowed` : null)}
  .icon {
    ${tw`w-6 h-6 -ml-2`}
  }
  .text {
    ${tw`ml-3`}
  }
`;
const OtherButton = styled.button`
  ${tw`w-64 sm:ml-8 mt-5 tracking-wide font-semibold bg-primary-500 text-gray-100 py-4 rounded-lg hover:bg-primary-700 transition-all duration-300 ease-in-out flex items-center justify-center focus:shadow-outline focus:outline-none`}
  ${({ disabled }) => (disabled ? tw`cursor-not-allowed` : null)}
  .icon {
    ${tw`w-6 h-6 -ml-2`}
  }
  .text {
    ${tw`ml-3`}
  }
`;

export const AlertDialog = ({
  title,
  message,
  displayModal,
  mode,
  dismissText,
  dismissAction,
  otherText,
  otherAction,
  otherIcon,
}: any) => {
  const cancelRef = useRef() as React.MutableRefObject<HTMLInputElement>;
  return (
    displayModal && (
      <ReactAlertDialog
        leastDestructiveRef={cancelRef}
        onDismiss={dismissAction}
        tw="w-screen lg:w-1/2 sm:p-8 lg:p-16"
      >
        <Container>
          <Content>
            <AlertDialogLabel>
              <Heading>{title}</Heading>
            </AlertDialogLabel>
            {mode === 'success' && (
              <FontAwesomeIcon icon={faCheckCircle} size="5x" color="#2C663C" />
            )}
            {mode === 'error' && (
              <FontAwesomeIcon icon={faExclamationTriangle} size="5x" color="#f56565" />
            )}
            {mode === 'info' && <FontAwesomeIcon icon={faInfoCircle} size="5x" color="#2C663C" />}
            <AlertDialogDescription>
              {message && mode === 'error' ? (
                <ErrorText>{message}</ErrorText>
              ) : (
                <StandardText>{message}</StandardText>
              )}
            </AlertDialogDescription>
            <ButtonContainer>
              <DismissButton ref={cancelRef as any} onClick={dismissAction}>
                {dismissText}
              </DismissButton>
              {otherAction && (
                <OtherButton onClick={otherAction}>
                  {otherIcon && <FontAwesomeIcon icon={otherIcon} size="1x" color="#FFF" />}
                  <span className="text">{otherText}</span>
                </OtherButton>
              )}
            </ButtonContainer>
          </Content>
        </Container>
      </ReactAlertDialog>
    )
  );
};
