// eslint-disable-next-line
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { parseQueryString } from '../../utils/parseQueryString';
import { withFirebase } from '../../services';
import * as ROUTES from '../../constants/routes';
import Loading from '../../utils/loadingAnimation';
/* eslint-disable  @typescript-eslint/no-unused-vars */
import tw from 'twin.macro';
import { faEnvelope, faChevronRight, faUserCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import validateField from '../../utils/validateField';
import { capitalizeFirstLetter } from '../../utils/capitalizeFirstLetter';

interface Props {
  firebase: any;
}

interface State {
  noResults: string;
  loading: boolean;
  users: any;
  searchTerm: any;
}

class UserList extends Component<Props, State> {
  unsubscribe: any;

  constructor(props: any) {
    super(props);

    const query = window.location.search.substring(1);
    const qs = parseQueryString(query);

    const searchTerm = qs ? qs : '';

    this.state = {
      noResults: '',
      loading: false,
      users: [],
      searchTerm,
    };
  }

  createQuery = () => {
    const searchTerm = this.state.searchTerm.s;
    const operator = '==';
    let query;

    if (validateField.isEmail(searchTerm) === true) {
      query = {
        field: 'email',
        operator,
        searchTerm: searchTerm,
      };
    } else if (
      validateField.hasSpaces(searchTerm) === true &&
      validateField.hasNumber(searchTerm) === false
    ) {
      // Req is for only first & last name... let's keep this simple for now
      const nameArray = searchTerm.split(/\+/g).filter(Boolean);
      query = {
        field: 'firstName',
        fieldLastName: 'lastName',
        operator,
        searchTerm: capitalizeFirstLetter(nameArray[0]),
        searchTermLastName: capitalizeFirstLetter(nameArray[1]),
      };
    } else if (validateField.hasNumber(searchTerm) === true) {
      query = {
        field: 'uid',
        operator,
        searchTerm: searchTerm.replace(/\+/g, ''),
      };
    } else {
      query = {
        field: false,
        operator,
        searchTerm: searchTerm ? searchTerm.replace(/\+/g, '') : null,
      };
    }

    return query;
  };

  loadingState = (userArray: any) => {
    if (userArray.length > 0) {
      this.setState({
        loading: false,
        users: userArray,
      });
    } else {
      this.setState({
        noResults: 'No results found.',
        loading: false,
      });
    }
  };

  componentDidMount() {
    this.setState({ loading: true });

    const db = this.props.firebase;
    const search = this.createQuery();
    if (!search.field) {
      this.setState({
        noResults: search.searchTerm ? `No results found for: ${search.searchTerm}` : ` `,
        loading: false,
      });
      return;
    }

    this.unsubscribe = () => {
      let dbQuery;
      const users: any = [];

      if (search.field === 'email') {
        dbQuery = db.users().where(search.field, search.operator, search.searchTerm);
        dbQuery.onSnapshot((snapshot: any) => {
          snapshot.forEach((doc: any) => {
            users.push({ ...doc.data(), uid: doc.id });
          });
          this.loadingState(users);
        });
      } else if (search.field === 'uid') {
        dbQuery = db.users().doc(search.searchTerm);
        dbQuery.onSnapshot((snapshot: any) => {
          users.push({ ...snapshot.data(), uid: snapshot.id });
          this.loadingState(users);
        });
      } else {
        dbQuery = db
          .users()
          .where(search.field, search.operator, search.searchTerm)
          .where(search.fieldLastName, search.operator, search.searchTermLastName);
        dbQuery.onSnapshot((snapshot: any) => {
          snapshot.forEach((doc: any) => {
            users.push({ ...doc.data(), uid: doc.id });
          });
          this.loadingState(users);
        });
      }
    };

    this.unsubscribe();
  }

  render() {
    const { users, loading, noResults }: any = this.state;

    return (
      <div tw="flex md:ml-0 bg-white shadow overflow-hidden flex-col">
        {loading && <Loading />}
        {noResults && <p tw="m-12">{noResults}</p>}
        <ul>
          {users.map((user: any) => (
            <li key={user.uid}>
              <Link
                tw="block hover:bg-primary-500 focus:outline-none focus:bg-primary-100 transition duration-150 ease-in-out"
                to={{
                  pathname: `${ROUTES.ADMIN}/${user.uid}`,
                  state: { isSent: true, user },
                }}
              >
                <div tw="flex items-center px-4 py-4 sm:px-6">
                  <div tw="min-w-0 flex-1 flex items-center">
                    <div tw="flex-shrink-0">
                      {user.photoURL ? (
                        <img tw="h-12 w-12 rounded-full" src={user.photoURL} alt="" />
                      ) : (
                        <FontAwesomeIcon size="3x" icon={faUserCircle} />
                      )}
                    </div>
                    <div tw="min-w-0 flex-1 px-4 md:grid md:grid-cols-2 md:gap-4">
                      <div>
                        <div tw="leading-5 font-medium text-secondary-900 truncate font-semibold text-lg">
                          {user.firstName && user.firstName} {user.lastName && user.lastName}
                        </div>
                        <div tw="mt-2 flex items-center leading-5 text-secondary-800">
                          <div tw="flex-shrink-0 mr-1 h-5 w-5 text-secondary-400">
                            {user.email ? <FontAwesomeIcon icon={faEnvelope} /> : null}
                          </div>
                          <span tw="truncate">{user.email ? user.email : null}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div tw="h-5 w-5 text-secondary-800">
                    <FontAwesomeIcon icon={faChevronRight} />
                  </div>
                </div>
              </Link>
            </li>
          ))}
        </ul>
      </div>
    );
  }
}

export default withFirebase(UserList);
