// eslint-disable-next-line
import React from 'react'
import { faLock, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
/* eslint-disable  @typescript-eslint/no-unused-vars */
import tw from 'twin.macro';

interface PWRIProps {
  firebase?: any;
  onTypePW: any;
  onSubmitChangePW: any;
}

interface PWRIState {
  password: string;
}

const INITIAL_STATE = {
  password: '',
};

class PWResetInput extends React.Component<PWRIProps, PWRIState> {
  constructor(props: any) {
    super(props);

    this.state = {
      ...INITIAL_STATE,
    };
  }

  onTextChange = (e: any) => {
    const password = e.target.value;
    this.props.onTypePW(password);
    this.setState({ password });
  };

  onKeyPress = (e: any) => {
    if (e.which === 13) {
      this.onSubmitPW();
    }
  };

  onSubmitPW = () => {
    this.props.onSubmitChangePW();
  };

  render() {
    return (
      <div tw="mt-5 flex rounded-md shadow-sm">
        <div tw="relative flex-grow focus-within:z-10">
          <div tw="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
            <FontAwesomeIcon icon={faLock} />
          </div>
          <input
            id="password"
            tw="form-input block w-full rounded-none rounded-l-md pl-10 transition ease-in-out duration-150 sm:text-sm sm:leading-5 border border-secondary-900 text-gray-900 placeholder-gray-800 focus:outline-none focus:placeholder-gray-600 sm:text-sm"
            placeholder="Password"
            value={this.state.password}
            onChange={this.onTextChange}
            onKeyPress={this.onKeyPress}
          ></input>
        </div>
        <button
          tw="-ml-px relative inline-flex items-center px-4 py-2 border border-secondary-900 leading-5 font-medium rounded-r-md text-secondary-700 focus:outline-none focus:border-primary-300 active:bg-primary-100 transition ease-in-out duration-150"
          onClick={this.onSubmitPW}
        >
          <FontAwesomeIcon icon={faChevronRight} />
          <span tw="ml-2">Reset</span>
        </button>
      </div>
    );
  }
}

export default PWResetInput;
