// eslint-disable-next-line
import React, { Component } from 'react';
import * as yup from 'yup';
import { Formik } from 'formik';
import { withRouter } from 'react-router-dom';
import { withFirebase } from '../services';
import * as ROUTES from '../constants/routes';
import googleIconImageSrc from '../assets/images/icons/google-icon.png';
import { AlertDialog } from '../components/alertDialog';
import tw from 'twin.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserPlus } from '@fortawesome/free-solid-svg-icons';
import Loading from '../utils/loadingAnimation';
import styled from 'styled-components';
import { Form as FormikForm } from 'formik';
const FormContainer = tw.div`w-full flex-1 mt-8`;
const DividerTextContainer = tw.div`my-12 border-b text-center relative`;
const DividerText = tw.div`leading-none px-2 inline-block text-sm text-gray-600 tracking-wide font-medium transform -translate-y-1/2 absolute inset-x-0 top-1/2 bg-white`;
const Form = styled(FormikForm)`mx-auto max-w-xs`;
const Input = tw.input`w-full px-8 py-4 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white mt-5 first:mt-0`;
const ErrorText = tw.p`text-red-400 text-sm italic text-center py-4`;
const SubmitButton = styled.button`
  ${tw`cursor-pointer mt-5 tracking-wide font-semibold bg-primary-500 text-gray-100 w-full py-4 rounded-lg hover:bg-primary-700 transition-all duration-300 ease-in-out flex items-center justify-center focus:shadow-outline focus:outline-none`}
  ${({ disabled }) => (disabled ? tw`cursor-not-allowed` : null)}
  .icon {
    ${tw`w-6 h-6 -ml-2`}
  }
  .text {
    ${tw`ml-3`}
  }
`;
const SocialButtonsContainer = tw.div`flex flex-col items-center`;
const SocialButton = styled.div`
  ${tw`cursor-pointer w-full max-w-xs font-semibold tracking-wide rounded-lg py-3 border text-gray-900 bg-gray-100 hocus:bg-gray-200 hocus:border-gray-400 flex items-center justify-center transition-all duration-300 focus:outline-none focus:shadow-outline text-sm mt-5 first:mt-0`}
  .iconContainer {
    ${tw`bg-white p-2 rounded-full`}
  }
  .icon {
    ${tw`w-4`}
  }
  .text {
    ${tw`ml-4`}
  }
`;

interface SUProps {
  firebase?: any;
  history?: any;
}

const INITIAL_STATE = {
  email: '',
  password: '',
  showDialog: false,
  error: null,
  loading: false,
  headingText: 'Sign Up',
  socialButtons: [
    {
      iconImageSrc: googleIconImageSrc,
      text: 'Sign Up / In',
    },
  ],
  submitButtonText: 'Sign Up / In',
  submitButtonIcon: faUserPlus,
  tosUrl: 'https://truthtotable.com/tos',
  privacyPolicyUrl: 'https://truthtotable.com/privacy',
  signInUrl: '/sign-in',
};

const ERROR_CODE_ACCOUNT_EXISTS = 'auth/email-already-in-use';

const ERROR_MSG_ACCOUNT_EXISTS = `
  An account with this email address address already exists.
  Try to login with this account instead.\n\n If you think the
  account is via your Google, try logging in with that. Afterward, associate your accounts
  on your PROFILE account page.
`;

class SignUpFormBase extends Component<SUProps> {
  constructor(props: any) {
    super(props);

    if (localStorage.getItem('authUser') !== null) this.props.history.push(ROUTES.DASHBOARD);

    this.state = {
      ...INITIAL_STATE,
    };
  }

  onChange = (event: any) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  signUpWithGoogle = () => {
    this.setState({ loading: true });
    this.props.firebase
      .doSignInWithGoogle()
      .then((socialAuthUser: any) => {
        return this.props.firebase.admin(socialAuthUser.user.uid).set(
          {
            username: socialAuthUser.user.displayName,
            email: socialAuthUser.user.email,
            customClaims: {
              roles: ['dashboardAccess'],
            },
          },
          { merge: true },
        );
      })
      .then(() => {
        let route;
        if (!this.props.firebase.auth.currentUser.emailVerified) {
          this.props.firebase.doSendEmailVerification();
          route = ROUTES.FORGOT_PASSWORD;
        } else {
          route = ROUTES.DASHBOARD;
        }
        this.setState({ ...INITIAL_STATE });
        this.props.history.push(route);
      })
      .catch((error: any) => {
        if (error.code === ERROR_CODE_ACCOUNT_EXISTS) {
          error.message = ERROR_MSG_ACCOUNT_EXISTS;
        }
        this.setState({ error, showDialog: true, loading: false });
      });
  };

  closeModal = () => this.setState({ showDialog: false, loading: false });

  navigateToSignIn = (event: any) => {
    event.preventDefault();
    this.props.history.push(ROUTES.SIGN_IN);
  };

  trySignInWithEmail(email: string, password: string) {
    this.setState({ loading: true });
    this.props.firebase
      .doSignInWithEmailAndPassword(email, password)
      .then((authUser: any) => {
        console.info(authUser);
        return this.props.firebase.admin(authUser.user.uid).set(
          {
            email,
          },
          { merge: true },
        );
      })
      .then(() => {
        let route;
        if (!this.props.firebase.auth.currentUser.emailVerified) {
          this.props.firebase.doSendEmailVerification();
          route = ROUTES.FORGOT_PASSWORD;
        } else {
          route = ROUTES.DASHBOARD;
        }
        this.setState({ ...INITIAL_STATE });
        this.props.history.push(route);
      })
      .catch((error: any) => {
        this.setState({ error, showDialog: true, loading: false });
      });
  }

  render() {
    const {
      socialButtons,
      submitButtonIcon,
      tosUrl,
      privacyPolicyUrl,
      error,
      showDialog,
      loading,
    }: any = this.state;
    return (
      <FormContainer>
        {loading ? (
          <Loading />
        ) : (
          <SocialButtonsContainer>
            {socialButtons.map((socialButton: any, index: any) => (
              <SocialButton key={index} onClick={() => this.signUpWithGoogle()}>
                <span className="iconContainer">
                  <img src={socialButton.iconImageSrc} className="icon" alt="" />
                </span>
                <span className="text">{socialButton.text}</span>
              </SocialButton>
            ))}
          </SocialButtonsContainer>
        )}
        {window.location.host !== 'admin.truthtotable.com' && (
          <>
            <DividerTextContainer>
              <DividerText>Or Sign Up with Your Email</DividerText>
            </DividerTextContainer>
            <Formik
              initialValues={{ email: '', password: '' }}
              onSubmit={async (values: any, { setSubmitting }) => {
                setSubmitting(true);
                this.setState({ loading: true });
                const { email, password } = values;
                this.props.firebase
                  .doCreateUserWithEmailAndPassword(email, password)
                  .then((authUser: any) => {
                    console.info(authUser);
                    return this.props.firebase.admin(authUser.user.uid).set(
                      {
                        email,
                      },
                      { merge: true },
                    );
                  })
                  .then(() => {
                    setSubmitting(false);
                    let route;
                    if (!this.props.firebase.auth.currentUser.emailVerified) {
                      this.props.firebase.doSendEmailVerification();
                      route = ROUTES.FORGOT_PASSWORD;
                    } else {
                      route = ROUTES.DASHBOARD;
                    }
                    this.setState({ ...INITIAL_STATE });
                    this.props.history.push(route);
                  })
                  .catch((error: any) => {
                    if (error.code === ERROR_CODE_ACCOUNT_EXISTS) {
                      error.message = ERROR_MSG_ACCOUNT_EXISTS;
                      this.trySignInWithEmail(email, password);
                    } else this.setState({ error, showDialog: true, loading: false });
                    setSubmitting(false);
                  });
              }}
              validationSchema={yup.object().shape({
                email: yup
                  .string()
                  .label('Email')
                  .email('Invalid Email Address')
                  .required('Email Address is Required'),
                password: yup.string().label('Password').min(8).required('Password is Required'),
              })}
            >
              {({
                values,
                handleChange,
                errors,
                setFieldTouched,
                touched,
                isValid,
                handleSubmit,
                isSubmitting,
              }: any) => {
                return (
                  <>
                    <Form>
                      <AlertDialog
                        title={'Something Went Wrong...'}
                        message={error && error.message}
                        displayModal={showDialog}
                        mode="error"
                        dismissText="Dismiss"
                        dismissAction={() => this.closeModal()}
                      />
                      <Input
                        required
                        type="email"
                        name="email"
                        value={values.email}
                        onChange={handleChange('email')}
                        onBlur={() => setFieldTouched('email')}
                        placeholder="Email Address"
                      />
                      {touched.email && errors.email && <ErrorText>{errors.email}</ErrorText>}
                      <Input
                        required
                        name="password"
                        type="password"
                        placeholder="Password"
                        value={values.password}
                        onChange={handleChange('password')}
                        onBlur={() => setFieldTouched('password')}
                      />
                      {touched.password && errors.password && (
                        <ErrorText>{errors.password}</ErrorText>
                      )}
                      <SubmitButton disabled={!isValid} onClick={handleSubmit}>
                        <FontAwesomeIcon icon={submitButtonIcon} />
                        <span className="text">{isSubmitting ? 'Processing...' : 'Sign Up'}</span>
                      </SubmitButton>
                      <p tw="mt-6 text-xs text-gray-600 text-center">
                        I agree to Truth To Tables&apos;s
                        <br />
                        <a
                          href={tosUrl}
                          target="_blank"
                          rel="noopener noreferrer"
                          tw="border-b border-gray-500 border-dotted"
                        >
                          Terms of Service
                        </a>{' '}
                        and its{' '}
                        <a
                          href={privacyPolicyUrl}
                          target="_blank"
                          rel="noopener noreferrer"
                          tw="border-b border-gray-500 border-dotted"
                        >
                          Privacy Policy
                        </a>
                      </p>
                      <p tw="mt-8 text-sm text-gray-600 text-center">
                        Already have an account?
                        <br />
                        <a
                          href="sign-in"
                          onClick={(event) => this.navigateToSignIn(event)}
                          tw="border-b border-gray-500 border-dotted"
                        >
                          Sign In
                        </a>
                      </p>
                    </Form>
                  </>
                );
              }}
            </Formik>
          </>
        )}
      </FormContainer>
    );
  }
}

const SignUpForm = withRouter(withFirebase(SignUpFormBase));

export { SignUpForm };
