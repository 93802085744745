// eslint-disable-next-line
import React, { Component } from 'react';
import * as yup from 'yup';
import { Formik } from 'formik';
import { withRouter } from 'react-router-dom';
import { withFirebase } from '../services';
import * as ROUTES from '../constants/routes';
import googleIconImageSrc from '../assets/images/icons/google-icon.png';
import { AlertDialog } from '../components/alertDialog';
import '@reach/dialog/styles.css';
/* eslint-disable  @typescript-eslint/no-unused-vars */
import tw from 'twin.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignInAlt, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import Loading from '../utils/loadingAnimation';
import styled from 'styled-components';
import { Form as FormikForm } from 'formik';

const FormContainer = tw.div`w-full flex-1 mt-8`;
const DividerTextContainer = tw.div`my-12 border-b text-center relative`;
const DividerText = tw.div`leading-none px-2 inline-block text-sm text-gray-600 tracking-wide font-medium transform -translate-y-1/2 absolute inset-x-0 top-1/2 bg-white`;
const Form = styled(FormikForm)`mx-auto max-w-xs`;
const Input = tw.input`w-full px-8 py-4 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white mt-5 first:mt-0`;
const ErrorText = tw.p`text-red-400 text-sm italic text-center py-4`;
const SubmitButton = styled.button`
  ${tw`mt-5 cursor-pointer tracking-wide font-semibold bg-primary-500 text-gray-100 w-full py-4 rounded-lg hover:bg-primary-700 transition-all duration-300 ease-in-out flex items-center justify-center focus:shadow-outline focus:outline-none`}
  ${({ disabled }) => (disabled ? tw`cursor-not-allowed` : null)}
  .icon {
    ${tw`w-6 h-6 -ml-2`}
  }
  .text {
    ${tw`ml-3`}
  }
`;
const SocialButtonsContainer = tw.div`flex flex-col items-center`;
const SocialButton = styled.div`
  ${tw`w-full cursor-pointer max-w-xs font-semibold rounded-lg py-3 border text-gray-900 bg-gray-100 hocus:bg-gray-200 hocus:border-gray-400 flex items-center justify-center transition-all duration-300 focus:outline-none focus:shadow-outline text-sm mt-5 first:mt-0`}
  .iconContainer {
    ${tw`bg-white p-2 rounded-full`}
  }
  .icon {
    ${tw`w-4`}
  }
  .text {
    ${tw`ml-4`}
  }
`;

interface SUProps {
  firebase?: any;
  history?: any;
}

const INITIAL_STATE = {
  email: '',
  password: '',
  showDialog: false,
  error: null,
  loading: false,
  headingText: 'Sign In',
  socialButtons: [
    {
      iconImageSrc: googleIconImageSrc,
      text: 'Sign In',
    },
  ],
  submitButtonText: 'Sign In',
  submitButtonIcon: faSignInAlt,
  forgotPasswordUrl: '/forgot-password',
};

class SignInFormBase extends Component<SUProps> {
  constructor(props: any) {
    super(props);

    if (localStorage.getItem('authUser') !== null) this.props.history.push(ROUTES.DASHBOARD);

    this.state = {
      ...INITIAL_STATE,
    };
  }

  onChange = (event: any) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  SignInWithGoogle = () => {
    this.setState({ loading: true });
    this.props.firebase
      .doSignInWithGoogle()
      .then((socialAuthUser: any) => {
        console.info('socialAuthUser -> ', socialAuthUser);
        return this.props.firebase.admin(socialAuthUser.user.uid).set(
          {
            username: socialAuthUser.user.displayName,
            email: socialAuthUser.user.email,
            roles: {},
          },
          { merge: true },
        );
      })
      .then(() => {
        let route;
        if (!this.props.firebase.auth.currentUser.emailVerified) {
          this.props.firebase.doSendEmailVerification();
          route = ROUTES.FORGOT_PASSWORD;
        } else {
          route = ROUTES.DASHBOARD;
        }
        this.setState({ ...INITIAL_STATE });
        this.props.history.push(route);
      })
      .catch((error: any) => {
        this.setState({ error, showDialog: true, loading: false });
      });
  };

  closeModal = () => this.setState({ showDialog: false, loading: false });

  navigateToSignUp = (event: any) => {
    event.preventDefault();
    this.props.history.push(ROUTES.SIGN_UP);
  };

  navigateToForgotPassword = (event: any) => {
    event.preventDefault();
    this.props.history.push(ROUTES.FORGOT_PASSWORD);
  };

  render() {
    const {
      socialButtons,
      submitButtonIcon,
      forgotPasswordUrl,
      error,
      showDialog,
      loading,
    }: any = this.state;
    return (
      <FormContainer>
        {loading ? (
          <Loading />
        ) : (
          <SocialButtonsContainer>
            {socialButtons.map((socialButton: any, index: any) => (
              <SocialButton key={index} onClick={() => this.SignInWithGoogle()}>
                <span className="iconContainer">
                  <img src={socialButton.iconImageSrc} className="icon" alt="" />
                </span>
                <span className="text">{socialButton.text}</span>
              </SocialButton>
            ))}
          </SocialButtonsContainer>
        )}
        {window.location.host !== 'admin.truthtotable.com' && (
          <>
            <DividerTextContainer>
              <DividerText>Or Sign In with Your Email</DividerText>
            </DividerTextContainer>
            <Formik
              initialValues={{ email: '', password: '' }}
              onSubmit={async (values: any, { setSubmitting }) => {
                setSubmitting(true);
                this.setState({ loading: true });
                const { email, password } = values;
                this.props.firebase
                  .doSignInWithEmailAndPassword(email, password)
                  .then((authUser: any) => {
                    console.info(authUser);
                    return this.props.firebase.admin(authUser.user.uid).set(
                      {
                        email,
                      },
                      { merge: true },
                    );
                  })
                  .then(() => {
                    setSubmitting(false);
                    let route;
                    if (!this.props.firebase.auth.currentUser.emailVerified) {
                      this.props.firebase.doSendEmailVerification();
                      route = ROUTES.FORGOT_PASSWORD;
                    } else {
                      route = ROUTES.DASHBOARD;
                    }
                    this.setState({ ...INITIAL_STATE });
                    this.props.history.push(route);
                  })
                  .catch((error: any) => {
                    this.setState({ error, showDialog: true, loading: false });
                    setSubmitting(false);
                  });
              }}
              validationSchema={yup.object().shape({
                email: yup
                  .string()
                  .label('Email')
                  .email('Invalid Email Address')
                  .required('Email Address is Required'),
                password: yup.string().label('Password').min(8).required('Password is Required'),
              })}
            >
              {({
                values,
                handleChange,
                errors,
                setFieldTouched,
                touched,
                isValid,
                handleSubmit,
                isSubmitting,
              }: any) => {
                return (
                  <>
                    <Form>
                      <AlertDialog
                        title={'Something Went Wrong...'}
                        message={error && error.message}
                        displayModal={showDialog}
                        mode="error"
                        dismissText="Dismiss"
                        dismissAction={() => this.closeModal()}
                        otherText="Forgot Your Password?"
                        otherAction={() => this.props.history.push(ROUTES.FORGOT_PASSWORD)}
                        otherIcon={faEnvelope}
                      />
                      <Input
                        type="email"
                        name="email"
                        value={values.email}
                        onChange={handleChange('email')}
                        onBlur={() => setFieldTouched('email')}
                        placeholder="Email Address"
                      />
                      {touched.email && errors.email && <ErrorText>{errors.email}</ErrorText>}
                      <Input
                        name="password"
                        type="password"
                        placeholder="Password"
                        value={values.password}
                        onChange={handleChange('password')}
                        onBlur={() => setFieldTouched('password')}
                      />
                      {touched.password && errors.password && (
                        <ErrorText>{errors.password}</ErrorText>
                      )}
                      <p tw="mt-6 mb-12 text-sm text-gray-600 text-center">
                        <a
                          href={forgotPasswordUrl}
                          onClick={(event: any) => this.navigateToForgotPassword(event)}
                          tw="border-b border-gray-500 border-dotted"
                        >
                          Forgot Your Password?
                        </a>
                      </p>
                      <SubmitButton disabled={!isValid} onClick={handleSubmit}>
                        <FontAwesomeIcon icon={submitButtonIcon} />
                        <span className="text">{isSubmitting ? 'Processing...' : 'Sign In'}</span>
                      </SubmitButton>
                      <p tw="mt-8 text-sm text-gray-600 text-center">
                        Don&apos;t have an account?
                        <br />
                        <a
                          href="sign-up"
                          onClick={(event: any) => this.navigateToSignUp(event)}
                          tw="border-b border-gray-500 border-dotted"
                        >
                          Sign Up
                        </a>
                      </p>
                    </Form>
                  </>
                );
              }}
            </Formik>
          </>
        )}
      </FormContainer>
    );
  }
}

const SignInForm = withRouter(withFirebase(SignInFormBase));

export { SignInForm };
