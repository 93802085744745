export const LANDING = '/';
export const SIGN_UP = '/sign-up';
export const SIGN_IN = '/sign-in';
export const DASHBOARD = '/dashboard';
export const AUTHENTICATION = '/account/authentication';
export const PROFILE = '/account/profile';
export const NOTIFICATIONS = '/account/notifications';
export const FORGOT_PASSWORD = '/forgot-password';
export const SUBSCRIBE = '/account/subscription';
export const ADMIN = '/admin';
export const ADMIN_DETAILS = '/admin/:id';
