// eslint-disable-next-line
import React, { Component } from 'react';
import { compose } from 'recompose';
/* eslint-disable  @typescript-eslint/no-unused-vars */
import tw from 'twin.macro';
import { AuthUserContext, withAuthorization, withEmailVerification } from '../../services/Session';
import { withFirebase } from '../../services';
import PasswordChangeForm from './PasswordChanges';
import { capitalizeFirstLetter } from '../../utils/capitalizeFirstLetter';
import googleIconImageSrc from '../../assets/images/icons/google-icon.png';
import Alert from '@reach/alert';
import * as yup from 'yup';
import { Formik, Form as FormikForm } from 'formik';
import Loading from '../../utils/loadingAnimation';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserLock } from '@fortawesome/free-solid-svg-icons';

const Form = styled(FormikForm)``;

const SIGN_IN_METHODS = [
  {
    id: 'password',
    provider: null,
  },
  {
    id: 'google',
    provider: 'googleProvider',
  },
];

const AuthenticationPage = (props: any) => (
  <AuthUserContext.Consumer>
    {(authUser: any) => (
      <div tw="self-center m-4 sm:m-12 md:mx-24 md:my-8">
        <div>
          <div>
            <div>
              <h3 tw="text-lg leading-6 font-medium text-gray-900">
                <FontAwesomeIcon icon={faUserLock} tw="mr-2" />
                Authentication
              </h3>
              <p tw="mt-1 text-sm leading-5 text-gray-500">
                Update your account authentication settings.
              </p>
            </div>
            <div tw="mt-6 grid grid-cols-1 row-gap-6 col-gap-4 sm:grid-cols-6">
              <div tw="sm:col-span-6">
                <label
                  htmlFor="Authentication"
                  tw="block text-sm font-semibold leading-5 text-gray-700"
                >
                  Login Management for: {authUser.email}
                </label>
                <LoginManagement authUser={authUser} firebase={props.firebase} />
              </div>

              <div tw="sm:col-span-6">
                <label
                  htmlFor="Change Password"
                  tw="block text-sm font-semibold leading-5 text-gray-700"
                >
                  Reset Password
                </label>
                <PasswordChangeForm />
              </div>
            </div>
          </div>
        </div>
      </div>
    )}
  </AuthUserContext.Consumer>
);

class LoginManagementBase extends Component<any> {
  constructor(props: any) {
    super(props);

    this.state = {
      activeSignInMethods: [],
      error: null,
    };
  }

  componentDidMount() {
    this.fetchSignInMethods();
  }

  fetchSignInMethods = () =>
    this.props.firebase.auth
      .fetchSignInMethodsForEmail(this.props.authUser.email)
      .then((activeSignInMethods: any) => this.setState({ activeSignInMethods, error: null }))
      .catch((error: any) => this.setState({ error }));

  onSocialLoginLink = (provider: any) =>
    this.props.firebase.auth.currentUser
      .linkWithPopup(this.props.firebase[provider])
      .then(this.fetchSignInMethods)
      .catch((error: any) => this.setState({ error }));

  onDefaultLoginLink = (password: any) => {
    const credential = this.props.firebase.emailAuthProvider.credential(
      this.props.authUser.email,
      password,
    );

    this.props.firebase.auth.currentUser
      .linkAndRetrieveDataWithCredential(credential)
      .then(this.fetchSignInMethods)
      .catch((error: any) => this.setState({ error }));
  };

  onUnlink = (providerId: any) =>
    this.props.firebase.auth.currentUser
      .unlink(providerId)
      .then(this.fetchSignInMethods)
      .catch((error: any) => this.setState({ error }));

  render() {
    const { activeSignInMethods, error }: any = this.state;
    return (
      <div>
        <ul>
          {SIGN_IN_METHODS.map((signInMethod) => {
            const onlyOneLeft = activeSignInMethods.length === 1;
            const isEnabled = activeSignInMethods.includes(signInMethod.id);

            return (
              <li key={signInMethod.id}>
                {signInMethod.id === 'password' ? (
                  <DefaultLoginToggle
                    onlyOneLeft={onlyOneLeft}
                    isEnabled={isEnabled}
                    signInMethod={signInMethod}
                    onLink={this.onDefaultLoginLink}
                    onUnlink={this.onUnlink}
                    firebase={this.props.firebase}
                  />
                ) : (
                  <SocialLoginToggle
                    onlyOneLeft={onlyOneLeft}
                    isEnabled={isEnabled}
                    signInMethod={signInMethod}
                    onLink={this.onSocialLoginLink}
                    onUnlink={this.onUnlink}
                    firebase={this.props.firebase}
                  />
                )}
              </li>
            );
          })}
        </ul>
        {error && (
          <Alert tw="my-8 w-full rounded-md bg-red-100 p-4">
            <div tw="flex">
              <div tw="flex-shrink-0">
                <svg tw="h-5 w-5 text-red-400" viewBox="0 0 20 20" fill="currentColor">
                  <path
                    fillRule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
                    clipRule="evenodd"
                  />
                </svg>
              </div>
              <div tw="ml-3">
                <h3 tw="text-sm leading-5 font-medium text-red-800">Something Went Wrong...</h3>
                <div tw="mt-2 text-sm leading-5 text-red-700">
                  <p>{error.message}</p>
                </div>
              </div>
            </div>
          </Alert>
        )}
      </div>
    );
  }
}

const SocialLoginToggle = ({ onlyOneLeft, isEnabled, signInMethod, onLink, onUnlink }: any) =>
  isEnabled ? (
    <button
      type="button"
      onClick={() => onUnlink(signInMethod.id)}
      disabled={onlyOneLeft}
      tw="w-full h-10 max-w-xs font-semibold tracking-wide rounded-lg py-3 border text-gray-900 bg-gray-100 hocus:bg-gray-200 hocus:border-gray-400 flex items-center justify-center transition-all duration-300 focus:outline-none focus:shadow-outline text-sm mt-5 first:mt-0"
    >
      <span className="iconContainer">
        <img src={googleIconImageSrc} className="icon" alt="" />
      </span>
      <span className="text" tw="block ml-2">
        Deactivate {capitalizeFirstLetter(signInMethod.id)} Authentication
      </span>
    </button>
  ) : (
    <button
      type="button"
      onClick={() => onLink(signInMethod.provider)}
      tw="w-full h-10 max-w-xs font-semibold tracking-wide rounded-lg py-3 border text-gray-900 bg-gray-100 hocus:bg-gray-200 hocus:border-gray-400 flex items-center justify-center transition-all duration-300 focus:outline-none focus:shadow-outline text-sm mt-5 first:mt-0"
    >
      <span className="iconContainer">
        <img src={googleIconImageSrc} className="icon" alt="Google" tw="h-6" />
      </span>
      <span className="text" tw="block ml-2">
        Link {capitalizeFirstLetter(signInMethod.id)}
      </span>
    </button>
  );

class DefaultLoginToggle extends Component<any, any> {
  onSubmit = (event: any) => {
    event.preventDefault();

    this.setState({ passwordOne: '', passwordTwo: '' });
  };

  onChange = (event: any) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    const {
      onlyOneLeft,
      isEnabled,
      signInMethod,
      onUnlink,
      error,
      loading,
      success,
    }: any = this.props;

    return isEnabled ? (
      <span tw="inline-flex rounded-md shadow-sm mt-4">
        <button
          type="button"
          onClick={() => onUnlink(signInMethod.id)}
          disabled={onlyOneLeft}
          tw="inline-flex justify-center mb-4 py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-primary-600 hover:bg-primary-500 focus:outline-none focus:border-primary-700 focus:shadow-outline active:bg-primary-700 transition duration-150 ease-in-out"
        >
          Deactivate {capitalizeFirstLetter(signInMethod.id)} Authentication
        </button>
      </span>
    ) : (
      <Formik
        initialValues={{ passwordOne: '', passwordTwo: '' }}
        onSubmit={async (values: any, { setSubmitting, resetForm }) => {
          setSubmitting(true);
          this.setState({ loading: true });
          const { passwordOne } = values;
          if (this.props.authUser === null || this.props.authUser === undefined)
            this.setState({
              error: {
                message:
                  'No email is associated with this account. Link a password above to enable password resets.',
              },
              loading: false,
              success: false,
            });

          this.props.firebase
            .doSignInWithGoogle()
            .then(() => {
              this.props
                .onLink(passwordOne)
                .then(() => {
                  resetForm({});
                  setSubmitting(false);
                  this.setState({ error: false, success: true, loading: false });
                })
                .catch((error: any) => {
                  setSubmitting(false);
                  resetForm({});
                  this.setState({ error, loading: false, success: false });
                });
            })
            .catch((error: any) => {
              resetForm({});
              this.setState({ error, success: false, loading: false });
            });
        }}
        validationSchema={yup.object().shape({
          passwordOne: yup.string().label('Password').min(8).required('Password is Required'),
          passwordTwo: yup
            .string()
            .label('Password Confirm')
            .min(8)
            .oneOf([yup.ref('passwordOne')], 'Passwords Do NOT Match')
            .required('Password confirm is required'),
        })}
      >
        {({
          values,
          handleChange,
          errors,
          setFieldTouched,
          isValid,
          touched,
          handleSubmit,
        }: any) => {
          return (
            <>
              {loading && <Loading />}
              <Form tw="mt-3 mb-8 grid grid-cols-1 row-gap-6 col-gap-4 sm:grid-cols-6">
                <div tw="sm:col-span-3">
                  <div tw="mt-1 rounded-md shadow-sm">
                    <input
                      name="passwordOne"
                      value={values.passwordOne}
                      onChange={handleChange('passwordOne')}
                      onBlur={() => setFieldTouched('passwordOne')}
                      type="password"
                      placeholder="New Password"
                      tw="form-input block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                    />
                  </div>
                </div>
                <div tw="sm:col-span-3">
                  <div tw="mt-1 rounded-md shadow-sm">
                    <input
                      name="passwordTwo"
                      value={values.passwordTwo}
                      onChange={handleChange('passwordTwo')}
                      onBlur={() => setFieldTouched('passwordTwo')}
                      type="password"
                      placeholder="Confirm New Password"
                      tw="form-input block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                    />
                  </div>
                </div>
                <div tw="sm:col-span-3">
                  <div tw="mt-1 rounded-md">
                    <button
                      disabled={!isValid}
                      onClick={handleSubmit}
                      type="submit"
                      tw="inline-flex justify-center py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-primary-600 hover:bg-primary-500 focus:outline-none focus:border-primary-700 focus:shadow-outline active:bg-primary-700 transition duration-150 ease-in-out"
                    >
                      Set Password
                    </button>
                  </div>
                </div>
              </Form>
              {success && (
                <Alert tw="my-8 w-full rounded-md bg-green-100 p-4">
                  <div tw="flex">
                    <div tw="flex-shrink-0">
                      <svg tw="h-5 w-5 text-green-400" viewBox="0 0 20 20" fill="currentColor">
                        <path
                          fillRule="evenodd"
                          d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </div>
                    <div tw="ml-3">
                      <h3 tw="text-sm leading-5 font-medium text-green-800">
                        Your password has been successfully set!
                      </h3>
                    </div>
                  </div>
                </Alert>
              )}
              {(error?.message || errors?.passwordTwo || errors?.passwordOne) &&
                (touched.passwordOne || touched.passwordTwo) && (
                  <Alert tw="my-8 w-full rounded-md bg-red-100 p-4">
                    <div tw="flex">
                      <div tw="flex-shrink-0">
                        <svg tw="h-5 w-5 text-red-400" viewBox="0 0 20 20" fill="currentColor">
                          <path
                            fillRule="evenodd"
                            d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
                            clipRule="evenodd"
                          />
                        </svg>
                      </div>
                      <div tw="ml-3">
                        <div tw="text-sm leading-5 text-red-700">
                          <p>{error && error.message}</p>
                          <p>{errors && errors.passwordOne}</p>
                          <p>{errors && errors.passwordTwo}</p>
                        </div>
                      </div>
                    </div>
                  </Alert>
                )}
            </>
          );
        }}
      </Formik>
    );
  }
}

const LoginManagement = withFirebase(LoginManagementBase);

const condition = (authUser: any) =>
  authUser &&
  (authUser.customClaims.roles.includes('admin') ||
    authUser.customClaims.roles.includes('customerService'));

export default compose(withEmailVerification, withAuthorization(condition))(AuthenticationPage);
