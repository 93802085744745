// eslint-disable-next-line
import React, { Component } from 'react';
import * as yup from 'yup';
import { Formik } from 'formik';
import { withRouter } from 'react-router-dom';
import { withFirebase } from '../services';
import { AlertDialog } from '../components/alertDialog';
import { navigate } from '@reach/router';
import * as ROUTES from '../constants/routes';
import tw from 'twin.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import Loading from '../utils/loadingAnimation';
import styled from 'styled-components';
import { Form as FormikForm } from 'formik';

const FormContainer = tw.div`w-full flex-1 mt-8`;
const Form = styled(FormikForm)`mx-auto max-w-xs`;
const Input = tw.input`w-full px-8 py-4 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white mt-5 first:mt-0`;
const ErrorText = tw.p`text-red-400 text-sm italic text-center py-4`;
const SubmitButton = styled.button`
  ${tw`mt-5 tracking-wide font-semibold bg-primary-500 text-gray-100 w-full py-4 rounded-lg hover:bg-primary-700 transition-all duration-300 ease-in-out flex items-center justify-center focus:shadow-outline focus:outline-none`}
  ${({ disabled }) => (disabled ? tw`cursor-not-allowed` : null)}
  .icon {
    ${tw`w-6 h-6 -ml-2`}
  }
  .text {
    ${tw`ml-3`}
  }
`;

interface SUProps {
  firebase?: any;
  history?: any;
}

const INITIAL_STATE = {
  email: '',
  showDialog: false,
  success: false,
  error: false,
  loading: false,
  submitButtonIcon: faEnvelope,
};

class ForgotPasswordFormBase extends Component<SUProps> {
  constructor(props: any) {
    super(props);

    this.state = {
      ...INITIAL_STATE,
    };
  }

  closeModal = () => this.setState({ showDialog: false });

  navigateBack = (event: any) => {
    event.preventDefault();
    return navigate(-1);
  };

  render() {
    const { submitButtonIcon, showDialog, success, error, loading }: any = this.state;
    return (
      <FormContainer>
        {window.location.host !== 'admin.truthtotable.com' && (
          <>
            <Formik
              initialValues={{ email: '' }}
              onSubmit={async (values: any, { setSubmitting }) => {
                setSubmitting(true);
                this.setState({ loading: true });
                const { email } = values;
                this.props.firebase
                  .doPasswordReset(email)
                  .then(() => {
                    setSubmitting(false);
                    this.setState({
                      email: '',
                      showDialog: true,
                      success: true,
                      error: null,
                      loading: false,
                    });
                  })
                  .catch((error: any) => {
                    this.setState({ error, showDialog: true, loading: false });
                    setSubmitting(false);
                  });
              }}
              validationSchema={yup.object().shape({
                email: yup
                  .string()
                  .label('Email Address')
                  .email('Invalid Email Address')
                  .required('Email Address is Required'),
              })}
            >
              {({
                values,
                handleChange,
                errors,
                setFieldTouched,
                touched,
                isValid,
                handleSubmit,
                isSubmitting,
              }: any) => {
                return (
                  <>
                    <Form>
                      {loading && <Loading />}
                      <AlertDialog
                        title={success ? 'Password Reset Success!' : 'Something Went Wrong...'}
                        message={
                          success
                            ? `A password reset link has been emailed to ${values.email}.`
                            : error.message
                        }
                        displayModal={showDialog}
                        dismissModal={() => this.closeModal()}
                        mode={success ? 'success' : 'error'}
                        dismissText={'Okay'}
                        dismissAction={() => {
                          if (success) {
                            this.props.history.push(ROUTES.FORGOT_PASSWORD);
                            this.closeModal();
                          } else {
                            this.closeModal();
                          }
                        }}
                      />
                      <Input
                        type="email"
                        name="email"
                        value={values.email}
                        onChange={handleChange('email')}
                        onBlur={() => setFieldTouched('email')}
                        placeholder="Email Address"
                      />
                      {touched.email && errors.email && <ErrorText>{errors.email}</ErrorText>}
                      <SubmitButton disabled={!isValid} onClick={handleSubmit} type="submit">
                        <FontAwesomeIcon icon={submitButtonIcon} />
                        <span className="text">
                          {isSubmitting ? 'Sending Password Reset Email...' : 'Reset Password'}
                        </span>
                      </SubmitButton>
                      <p tw="mt-8 text-sm text-gray-600 text-center cursor-pointer">
                        <a
                          href="/"
                          onClick={(event: any) => this.navigateBack(event)}
                          tw="border-b border-gray-500 border-dotted"
                        >
                          Go Back
                        </a>
                      </p>
                    </Form>
                  </>
                );
              }}
            </Formik>
          </>
        )}
      </FormContainer>
    );
  }
}

const ForgotPasswordForm = withRouter(withFirebase(ForgotPasswordFormBase));

export { ForgotPasswordForm };
