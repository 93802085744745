// eslint-disable-next-line
import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import ErrorBoundary from '../components/errorBoundary';
import SignUpPage from '../pages/SignUp';
import SignInPage from '../pages/SignIn';
import ForgotPasswordPage from '../pages/ForgotPassword';
import DashboardPage from '../pages/Auth/Dashboard';
import AuthenticationPage from '../pages/Auth/Authentication';
import AdminPage from '../pages/Auth/Admin';
import { Splash } from '../pages/Splash';
import Toolbar from './Toolbar';
import Navigation from './Navigation';
import * as ROUTES from '../constants/routes';
import { withAuthentication } from '../services/Session';
import tw from 'twin.macro';

const AppContainer = tw.div`text-secondary-900`;

const App = () => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <ErrorBoundary>
      <AppContainer>
        <Router>
          <div tw="flex">
            <Navigation isOpen={isOpen} setIsOpen={setIsOpen} />
            <div tw="flex flex-col w-0 flex-1">
              <Toolbar isOpen={isOpen} setIsOpen={setIsOpen} />
              <Switch>
                <Route exact path="/" component={SignUpPage} />
                <Route exact path={ROUTES.SIGN_UP} component={SignUpPage} />
                <Route exact path={ROUTES.SIGN_IN} component={SignInPage} />
                <Route exact path={ROUTES.FORGOT_PASSWORD} component={ForgotPasswordPage} />
                <Route exact path={ROUTES.DASHBOARD} component={DashboardPage} />
                <Route exact path={ROUTES.AUTHENTICATION} component={AuthenticationPage} />
                <Route exact path={ROUTES.ADMIN} component={AdminPage} />
                <Route exact path={ROUTES.ADMIN_DETAILS} component={AdminPage} />
                <Route default component={Splash} />
              </Switch>
            </div>
          </div>
        </Router>
      </AppContainer>
    </ErrorBoundary>
  );
};

export default withAuthentication(App);
