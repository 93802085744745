// eslint-disable-next-line
import React from 'react';
import { reloadBrowser } from '../utils';
/* eslint-disable  @typescript-eslint/no-unused-vars */
import tw from 'twin.macro';

export const Splash = (error: any) => {
  const { errorState } = error;
  console.error(errorState);
  return (
    <main tw="flex-1 relative z-0 overflow-y-auto py-6 focus:outline-none">
      <div tw="max-w-6xl mx-auto px-4 sm:px-6 md:px-8">
        <h1 tw="text-2xl font-semibold text-gray-900">{!errorState && <p>404 Not Found</p>}</h1>
      </div>
      <div tw="max-w-6xl mx-auto px-4 sm:px-6 md:px-8">
        <div tw="py-4">
          <div tw="border-4 border-dashed border-gray-200 rounded-lg h-96 cursor-pointer">
            {errorState !== undefined && errorState !== null && (
              <p onClick={reloadBrowser}>We&apos;re sorry, but something has gone wrong.</p>
            )}
          </div>
        </div>
      </div>
    </main>
  );
};
