// eslint-disable-next-line
import React, { Component } from 'react'
import SearchInput from '../components/SearchInput';
import { withRouter } from 'react-router-dom';
import { withFirebase } from '../services';
import * as ROUTES from '../constants/routes';
// import { UserList } from '../components/Users';
/* eslint-disable  @typescript-eslint/no-unused-vars */
import tw from 'twin.macro';

interface SProps {
  firebase?: any;
  history?: any;
  searchTerm: string;
}

interface SState {
  searchText: string;
}

const INITIAL_STATE = {
  searchText: '',
  //   results: array,
  //   showDialog: false,
  //   success: false,
  //   error: false,
  //   loading: false,
};

class Search extends Component<SProps, SState> {
  constructor(props: any) {
    super(props);

    this.state = {
      ...INITIAL_STATE,
    };
  }

  onSearchValue = (searchText: any) => {
    this.setState({ searchText });
  };

  onFormSubmit = () => {
    this.props.history.push(ROUTES.ADMIN);
  };

  render() {
    return (
      <form onSubmit={this.onFormSubmit} tw="w-full flex md:ml-0">
        <SearchInput onInputText={this.onSearchValue} />
      </form>
    );
  }
}

export default withRouter(withFirebase(Search));
