// eslint-disable-next-line
import React, { useRef, useEffect, useContext } from 'react';
import { CSSTransition as ReactCSSTransition } from 'react-transition-group';

const TransitionContext = React.createContext({
  parent: {},
});

const useIsInitialRender = () => {
  const isInitialRender = useRef(true);
  useEffect(() => {
    isInitialRender.current = false;
  }, []);
  return isInitialRender.current;
};

const CSSTransition = ({
  show,
  enter = '',
  enterFrom = '',
  enterTo = '',
  leave = '',
  leaveFrom = '',
  leaveTo = '',
  appear,
  children,
}: any) => {
  const enterClasses: any = enter.split(' ').filter((s: any) => s.length);
  const enterFromClasses: any = enterFrom.split(' ').filter((s: any) => s.length);
  const enterToClasses: any = enterTo.split(' ').filter((s: any) => s.length);
  const leaveClasses: any = leave.split(' ').filter((s: any) => s.length);
  const leaveFromClasses: any = leaveFrom.split(' ').filter((s: any) => s.length);
  const leaveToClasses: any = leaveTo.split(' ').filter((s: any) => s.length);

  const addClasses = (node: any, classes: any) => {
    classes.length && node.classList.add(...classes);
  };

  const removeClasses = (node: any, classes: any) => {
    classes.length && node.classList.remove(...classes);
  };

  return (
    <ReactCSSTransition
      appear={appear}
      unmountOnExit
      in={show}
      addEndListener={(node: any, done: any) => {
        node.addEventListener('transitionend', done, false);
      }}
      onEnter={(node: any) => {
        addClasses(node, [...enterClasses, ...enterFromClasses]);
      }}
      onEntering={(node: any) => {
        removeClasses(node, enterFromClasses);
        addClasses(node, enterToClasses);
      }}
      onEntered={(node: any) => {
        removeClasses(node, [...enterToClasses, ...enterClasses]);
      }}
      onExit={(node: any) => {
        addClasses(node, [...leaveClasses, ...leaveFromClasses]);
      }}
      onExiting={(node: any) => {
        removeClasses(node, leaveFromClasses);
        addClasses(node, leaveToClasses);
      }}
      onExited={(node: any) => {
        removeClasses(node, [...leaveToClasses, ...leaveClasses]);
      }}
    >
      {children}
    </ReactCSSTransition>
  );
};

const Transition = ({ show, appear, ...rest }: any) => {
  const { parent }: any = useContext(TransitionContext);
  const isInitialRender: any = useIsInitialRender();
  const isChild: any = show === undefined;

  if (isChild) {
    return (
      <CSSTransition
        appear={parent.appear || !parent.isInitialRender}
        show={parent.show}
        {...rest}
      />
    );
  }

  return (
    <TransitionContext.Provider
      value={{
        parent: {
          show,
          isInitialRender,
          appear,
        },
      }}
    >
      <CSSTransition appear={appear} show={show} {...rest} />
    </TransitionContext.Provider>
  );
};

export default Transition;
