// eslint-disable-next-line
import React from 'react';
import { Link } from 'react-router-dom';
/* eslint-disable  @typescript-eslint/no-unused-vars */
import tw from 'twin.macro';
import Transition from '../utils/transition';
import { AuthUserContext, withAuthentication } from '../services/Session';
import * as ROUTES from '../constants/routes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserPlus, faBars } from '@fortawesome/free-solid-svg-icons';
import horizontalLogo from '../../src/assets/images/icons/logo-horizontal.png';

const Navigation = (props: any) => (
  <AuthUserContext.Consumer>
    {(authUser) => (authUser ? <NavigationAuth {...props} /> : <NavigationNonAuth />)}
  </AuthUserContext.Consumer>
);

const NavigationAuth: any = ({ isOpen, setIsOpen }: any) =>
  isOpen ? (
    <>
      <div tw="md:hidden static">
        <div tw="fixed inset-0 flex z-40">
          <Transition
            show={isOpen}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div tw="fixed inset-0" onClick={() => setIsOpen(!isOpen)}>
              <div tw="absolute inset-0 bg-gray-600 opacity-75"></div>
            </div>
          </Transition>
          <Transition
            show={isOpen}
            enter="transition ease-in-out duration-300 transform"
            enterFrom="-translate-x-full"
            enterTo="translate-x-0"
            leave="transition ease-in-out duration-300 transform"
            leaveFrom="translate-x-0"
            leaveTo="-translate-x-full"
          >
            <div tw="relative flex-1 flex flex-col max-w-xs w-full pt-5 pb-4 bg-primary-800">
              <div tw="absolute top-0 right-0 -mr-16 p-1" onClick={() => setIsOpen(!isOpen)}>
                <button
                  tw="flex items-center justify-center h-12 w-12 rounded-full focus:outline-none focus:bg-gray-600"
                  aria-label="Close sidebar"
                >
                  <FontAwesomeIcon icon={faBars} />
                </button>
              </div>
              <div tw="flex-shrink-0 flex items-center px-4">
                <img tw="h-10 w-auto" src={horizontalLogo} alt="Truth To Table" />
              </div>
              <div tw="mt-5 flex-1 h-0 overflow-y-auto">
                <nav tw="px-2">
                  <div
                    className="group"
                    tw=" flex items-center px-2 py-2 text-lg leading-6 font-medium rounded-md text-white bg-primary-700 focus:outline-none focus:bg-primary-700 transition ease-in-out duration-150"
                  >
                    <Link to={ROUTES.DASHBOARD}>
                      <FontAwesomeIcon icon={faUserPlus} tw="mr-2" />
                      Dashboard
                    </Link>
                  </div>
                </nav>
              </div>
            </div>
          </Transition>
          <div tw="flex-shrink-0 w-12"></div>
        </div>
      </div>
    </>
  ) : (
    <>
      <div tw="hidden md:flex md:flex-shrink-0 static">
        <div tw="flex flex-col w-64 bg-primary-800 pt-5 pb-4">
          <div tw="flex items-center flex-shrink-0 px-4">
            <img tw="h-10 w-auto" src={horizontalLogo} alt="Truth To Table" />
          </div>
          <div tw="mt-5 h-0 flex-1 flex flex-col overflow-y-auto">
            <nav tw="flex-1 px-2 bg-primary-800">
              <div
                className="group"
                tw="flex items-center px-2 py-2 text-base leading-5 font-medium text-white rounded-md bg-primary-700 focus:outline-none focus:bg-primary-700 transition ease-in-out duration-150"
              >
                <Link to={ROUTES.DASHBOARD}>
                  <FontAwesomeIcon icon={faUserPlus} tw="mr-2" />
                  Dashboard
                </Link>
              </div>
            </nav>
          </div>
        </div>
      </div>
    </>
  );

const NavigationNonAuth: any = () => null;

// Should this only show if the person has access?
export default withAuthentication(Navigation);
